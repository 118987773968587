import * as React from "react";
import { Constants } from "../../@types/Constants";
import ErrorMessageAlert from "../../components/common/ErrorMessageAlert/ErrorMessageAlert";
import { container } from "./ResetPassword.module.scss";

export const Container = (props: any) => {
    return (<div className={container}>{props.children}</div>);
};

export const  TrackErrorMessage = (props: {message: string, field: string}) => {
    const trackMessage = props.message && typeof props.message !== undefined ? `${props.message} - ${props.field}` : '';
    return (
        <ErrorMessageAlert 
            errorType={Constants.ERRORSTYPE.TRAVELPASS} 
            errorSubType={Constants.ERRORSSUBTYPE.TRAVELPASS.RESETPASSWORD}
            message={trackMessage}
        >
            {props.message}
        </ErrorMessageAlert>
    );
};

